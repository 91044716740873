<template>
    <el-dialog :title="!dataForm.recId ? '新增专业' : '修改专业'" :close-on-click-modal="false" :visible.sync="visible"
        width="480px">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px" :inline="true" class="border-form">
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="专业名称" prop="majorName" class="item">
                        <el-input v-model="dataForm.majorName" :disabled="disabled" placeholder="请输入"
                            style="width: 280px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="专业代码" prop="majorCode" class="item">
                        <el-input v-model="dataForm.majorCode" :disabled="disabled" placeholder="请输入"
                            style="width: 280px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="set_border">
                <el-col :span="24" class="col-set_border">
                    <el-form-item label="排序" prop="sort" class="item">
                        <el-input type="number" v-model="dataForm.sort" placeholder="请输入" style="width: 280px;"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row class="formBtnGroup">
            <el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
            <el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
        </el-row>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "subjectInfo-add-or-update",
    data() {
        return {
            visible: false,
            action: '',
            disabled: false,
            dataForm: {
                recId: '',
                workNo: $common.getItem("workNo"),
                majorName: "",
                majorCode: "",
                sort: "",
                sysFlag: ''
            },
            dataRule: {
                majorName: [
                    { required: true, message: "请填写专业名称", trigger: "blur" }
                ],
                majorCode: [
                    { required: true, message: "请填写专业代码", trigger: "blur" }
                ],
                sort: [
                    { required: true, message: "请填写专业序号", trigger: "blur" }
                ]
            },
        }
    },
    methods: {
        init(id) {
            this.dataForm = {
                recId: '',
                workNo: $common.getItem("workNo"),
                majorName: "",
                majorCode: "",
                sort: ""
            };
            this.visible = true;
            this.dataForm.recId = id ? id : '';
            this.$nextTick(() => {
                this.$refs['dataForm'].resetFields();
                if (this.dataForm.recId) {
                    this.$http({
                        url: this.$store.state.httpUrl + `/business/projectMajorInfo/info/${this.dataForm.recId}`,
                        method: 'get',
                        params: {}
                    }).then(({ data }) => {
                        if (data && data.resultCode === 200) {
                            this.dataForm = data.body
                            if (this.dataForm.sysFlag == 1) {
                                this.disabled = true
                            } else {
                                this.disabled = false
                            }
                        }
                    })
                } else {
                    this.disabled = false
                }
            });
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    let _url = "/business/projectMajorInfo/save";
                    if (this.dataForm.recId) {
                        _url = "/business/projectMajorInfo/update";
                    }
                    this.$http({
                        url: this.$store.state.httpUrl + _url,
                        method: "post",
                        data: this.$http.adornData(this.dataForm)
                    }).then(({ data }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$parent.getDataList();
                                }
                            });
                        }
                    });
                }
            });
        },
    }
}
</script>
<style scoped>
.item::v-deep .el-form-item__label {
    color: #00428E !important;
    margin-right: 10px !important;
    font-weight: bold !important;
    background-color: #E1F1FF;
    white-space: nowrap !important;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
    border-bottom: solid #dadada 1px;
    border-left: solid #dadada 1px;
    border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
    border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
    display: flex !important;
    align-items: stretch !important;
    margin-bottom: 0px !important;
}
</style>
